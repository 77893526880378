import React from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import Certifications from "../../components/Certifications/Certifications";
import PageSection from "../../components/PageSection/PageSection";
import ProgramCard from "../../components/ProgramCard/ProgramCard";
import ContactSection from "../../components/ContactSection/ContactSection";
import FooterCTA from "../../components/FooterCTA/FooterCTA";
import IndustryCardGrid from "../../components/IndustryCardGrid/IndustryCardGrid";
import ScrollLocationOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";
import { Button } from "../../components/Button/Button";
import { Link } from "react-router-dom";
import { programInfo } from "../data.js";

function HomePage({ isMobile }) {
  return (
    <>
      <ScrollLocationOnMount />
      <HeroSection page="hero--homepage" />
      <Certifications />
      <PageSection
        isMobile={isMobile}
        sectionType="programs--section"
        headerTitle={
          <h2>
            <em>Flexible</em> programs built for <em>unique</em> businesses
          </h2>
        }
        headerDesc={
          <p>
            We offer a suite of financing options because we understand that no
            two businesses require the same solution. We also understand that
            speed is of the essence in today's financing environment, so we
            always aim for the quickest turnaround to fund on your schedule.
          </p>
        }
        bodyContent={programInfo.map((card) => (
          <ProgramCard
            key={card.title}
            cardTitle={card.title}
            cardName={card.name}
            cardImage={card.image}
            imageAlt={card.alt}
          />
        ))}
        CTA={
          <div className="button-container">
            <a href="../apply">
              <Button buttonStyle="btn--dark" buttonSize="btn--large">
                Apply Now
              </Button>
            </a>
          </div>
        }
      />
      <PageSection
        isMobile={isMobile}
        sectionType="industries--section"
        headerTitle={<h2>We Fund a Variety of Industries</h2>}
        headerDesc={
          <>
            <p>
              Focused Financing is devoted to helping businesses find the
              financial solutions their companies need. We understand that every
              business is different, so we uniquely tailor each option based on
              our client's requests, no matter how complex it may be.
            </p>
            <Link to="/industries" className="all-industries-link">
              <Button buttonStyle="btn--primary" buttonFunction="arrow">
                See all industries
              </Button>
            </Link>
          </>
        }
        bodyContent={<IndustryCardGrid spotlight={true} />}
      />
      <PageSection
        isMobile={isMobile}
        sectionType="contact--section"
        headerTitle={<h2>Contact Us</h2>}
        bodyContent={<ContactSection isMobile={isMobile} />}
      />
      <FooterCTA />
    </>
  );
}

export default HomePage;

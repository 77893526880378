import React, { useState, useEffect } from "react";
import "./Submitted.css";
import { Link, useNavigate } from "react-router-dom";

const Submitted = () => {
  const [time, setTime] = useState(10);
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((t) => t - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (time === -1) {
      navigate("/resources");
    }
  }, [time, navigate]);

  return (
    <section className="submission-thanks fixed-p2v-gradient">
      <h2>Your application has been successfully submitted!</h2>
      <h3>
        To ensure your file is quickly reviewed, please check your email for the
        next steps.
      </h3>
      <p>
        You will be redirected to the resources page in {time} seconds, or click{" "}
        {<Link to="/resources">here</Link>} to navigate immediately.
      </p>
    </section>
  );
};

export default Submitted;

import React from "react";
import "./SupplamentaryPage.css";

import Programs from "../../components/Programs/Programs";
import Partners from "../../components/Partners/Partners";
import IndustryCardGrid from "../../components/IndustryCardGrid/IndustryCardGrid";
import Resources from "../../components/Resources/Resources";
import ScrollLocationOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";
import Legal from "../../components/Legal/Legal";
import { useLocation } from "react-router-dom";
import { privatePolicy, termsAndConditions } from "../data.js";
import { IconContext } from "react-icons";
import FooterCTA from "../../components/FooterCTA/FooterCTA";

const PAGE_CONTENT = {
  "programs--page": {
    headerTitle: "Our Programs",
    headerDesc: `
        Focused offers a suite of financing options because we understand that
        no two businesses require the same solution. We also understand that
        speed is of the essence in today's financing environment, so we always
        aim for the quickest turnaround to fund on your schedule.
      `,
    body: <Programs />,
  },
  "industry--page": {
    headerTitle: "Industries We Serve",
    headerDesc: `
    Focused Financing is devoted to helping businesses find the financial solutions
    their companies need. We understand that every business is different, so we uniquely
    tailor each option based on our client's requests, no matter how complex it may be.${"\n"}

    Ultimately, your growth and success is our business, and we remain committed to building lasting relationships all of our clients and partners.
      `,
    body: <IndustryCardGrid />,
  },
  "partners--page": {
    headerTitle: "Become a Focused ISO Partner",
    headerDesc: `
        Partnering with Focused allows you to better-engage with your clients by
        offering financing options that help grow their businesses. You'll also
        benefit by connecting your brand with a trusted partner in the lending
        community. Whether you're an individual broker or established ISO, we
        have programs available for you and your clients.
      `,
    body: <Partners />,
  },
  "privacy--page": {
    headerTitle: "Private Policy",
    body: <Legal legalInfo={privatePolicy} />,
  },
  "terms--page": {
    headerTitle: "Terms and Conditions",
    body: <Legal legalInfo={termsAndConditions} />,
  },
  "resources--page": {
    headerTitle: "Resources and Templates For Small Businesses",
    headerDesc: `
        These are some of the most commonly requested documents when applying
        for financing. If you don't have in-house accounting, our templates are
        here to make applying easier. Documents vary by funding request, so be
        sure to check with your Funding Advisor.
      `,
    body: <Resources />,
  },
};

const SupplamentaryPage = ({ pageType }) => {
  const passedPage = PAGE_CONTENT.hasOwnProperty(pageType)
    ? PAGE_CONTENT[pageType]
    : PAGE_CONTENT[0];
  let location = useLocation();
  let url = location.pathname;
  let hash = location.hash;
  return (
    <>
      <ScrollLocationOnMount url={url} target={hash} />
      <header className="supplamentary-page-header">
        <div className="supplamentary-page-header-container">
          <h2 className="supplamentary-page-title">{passedPage.headerTitle}</h2>
          {passedPage.headerDesc && (
            <p className="supplamentary-page-description">
              {passedPage.headerDesc}
            </p>
          )}
        </div>
      </header>
      <IconContext.Provider value={{ color: "var(--focused-dark)" }}>
        <section className="supplamentary-body">
          <div className="supplamentary-body-container">{passedPage.body} </div>
        </section>
      </IconContext.Provider>
      <FooterCTA />
    </>
  );
};

export default SupplamentaryPage;

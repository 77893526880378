import EquipFinancing from "../assets/img/thumbnail-images/equip-financing.jpg";
import MCA from "../assets/img/thumbnail-images/mca.jpg";
import smBusLoan from "../assets/img/thumbnail-images/sm-bus-loan.jpg";
import applicationForm from "../assets/resources/Application.pdf";
import BalanceSheetTemplate from "../assets/resources/Balance-Sheet-Template.xlsx";
import DebtScheduleTemplate from "../assets/resources/Debt-Schedule-Template.xlsx";
import IncomeStatement from "../assets/resources/Income-Statement-Template.xlsx";
import PFSTemplate from "../assets/resources/PFS-Template.xlsx";
import {
  MdOutlineAgriculture,
  MdOutlineLocalMovies,
  MdCarRental,
  MdConstruction,
  MdOutlinePrecisionManufacturing,
  MdOutlineShoppingBag,
  MdHouse,
  MdCarRepair,
  MdOutlineAirportShuttle,
  MdOutlineHotel,
  MdOutlineBusiness,
} from "react-icons/md";
import {
  GiReceiveMoney,
  GiTowTruck,
  GiPayMoney,
  GiMining,
  GiFlatbedCovered,
  GiAirplaneDeparture,
  GiCircuitry,
  GiBroom,
} from "react-icons/gi";
import {
  FaBoxes,
  FaCarSide,
  FaCocktail,
  FaDumbbell,
  FaGasPump,
  FaGraduationCap,
  FaPhoneAlt,
  FaPrayingHands,
  FaStethoscope,
} from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { RiRestaurantLine } from "react-icons/ri";
import { SiTransferwise } from "react-icons/si";
import { AiOutlineSafetyCertificate, AiOutlineStock } from "react-icons/ai";
import { HiSpeakerphone } from "react-icons/hi";
import { Link } from "react-router-dom";

export const programInfo = [
  {
    title: "Small Business Loan",
    name: "sm-bus-loan",
    image: smBusLoan,
    icon: GiReceiveMoney,
    alt: "Top-tier Business Loans",
    desc: "Long-term financing for business activities such as purchasing inventory, meeting payroll, renovating a location, refinancing debt, or just general expansion.",
    notes: [
      ["Approvals", "$1,000,000 max"],
      ["Term Length", "12 - 48 months"],
      ["Rates", "9.95% +"],
      ["Funding in", "3 - 5 days"],
    ],
  },
  {
    title: "Equipment Financing",
    name: "equipt-fin",
    image: EquipFinancing,
    icon: GiTowTruck,
    alt: "Easy Equipment Financing",
    desc: 'Affordable options for all industries, including non-essential healthcare devices. We structure our terms as an EFA, to avoid hidden fees as found in "fair market" agreements.',
    notes: [
      ["Approvals", "$3,000,000 max"],
      ["Term Length", "24 - 60 months"],
      ["Rates", "6.95% +"],
      ["Funding in", "1 - 2 days"],
    ],
  },
  {
    title: "Merchant Cash Advance",
    name: "merch-cash-adv",
    image: MCA,
    icon: GiPayMoney,
    alt: "Fast Merchant Cash Advances",
    desc: "A quick option offering funding based on future receivables, available for immediate use. Repaid through fixed ACH payments or as a percentage of credit card sales.",
    notes: [
      ["Approvals", "$1,000,000 max"],
      ["Term Length", "12 - 24 months"],
      ["Cost", "1.08 +"],
      ["Funding in", "24 hours"],
    ],
  },
];

export const industryArray = [
  {
    name: "Agriculture",
    icon: <MdOutlineAgriculture size={70} />,
    altText: "Agriculture, Forestry, Fishing, and Hunting",
    spotlight: false,
  },
  {
    name: "Arts & Media",
    icon: <MdOutlineLocalMovies size={70} />,
    altText: "Arts and Media",
    spotlight: false,
  },

  {
    name: "Auto Repair",
    icon: <MdCarRepair size={70} />,
    altText: "Automobile Repair",
    spotlight: true,
  },
  {
    name: "Auto Sales",
    icon: <MdCarRental size={70} />,
    altText: "Automobile Sales",
    spotlight: false,
  },
  {
    name: "Bars & Nightlife",
    icon: <FaCocktail size={70} />,
    altText: "Bars and Nightclubs",
    spotlight: false,
  },
  {
    name: "Consultation",
    icon: <MdOutlineBusiness size={70} />,
    altText: "Business Services",
    spotlight: false,
  },
  {
    name: "Janitorial",
    icon: <GiBroom size={70} />,
    altText: "Cleaning Services",
    spotlight: false,
  },
  {
    name: "Communications",
    icon: <FaPhoneAlt size={70} />,
    altText: "Construction",
    spotlight: false,
  },
  {
    name: "Construction",
    icon: <MdConstruction size={70} />,
    altText: "Construction",
    spotlight: true,
  },
  {
    name: "eCommerce",
    icon: <SiTransferwise size={70} />,
    altText: "eCommerce",
    spotlight: true,
  },
  {
    name: "Education",
    icon: <FaGraduationCap size={70} />,
    altText: "Education",
    spotlight: false,
  },
  {
    name: "Finance Services",
    icon: <AiOutlineStock size={70} />,
    altText: "Financial Service",
    spotlight: false,
  },
  {
    name: "Freight",
    icon: <GiFlatbedCovered size={70} />,
    altText: "Freight",
    spotlight: false,
  },
  {
    name: "Gas Stations",
    icon: <FaGasPump size={70} />,
    altText: "Gas Stations",
    spotlight: false,
  },
  {
    name: "Gyms & Fitness",
    icon: <FaDumbbell size={70} />,
    altText: "Gyms and Fitness",
    spotlight: false,
  },
  {
    name: "Health Services",
    icon: <FaStethoscope size={70} />,
    altText: "Healthcare",
    spotlight: true,
  },
  {
    name: "Hospitality",
    icon: <MdOutlineHotel size={70} />,
    altText: "Hotels and Lodging",
    spotlight: false,
  },
  {
    name: "Insurance",
    icon: <AiOutlineSafetyCertificate size={70} />,
    altText: "Insurance",
    spotlight: false,
  },
  {
    name: "Legal Services",
    icon: <GoLaw size={70} />,
    altText: "Legal Services",
    spotlight: false,
  },
  {
    name: "Manufacturing",
    icon: <MdOutlinePrecisionManufacturing size={70} />,
    altText: "Manufacturing",
    spotlight: false,
  },
  {
    name: "Marketing",
    icon: <HiSpeakerphone size={70} />,
    altText: "Marketing and Advertising",
    spotlight: false,
  },
  {
    name: "Mining",
    icon: <GiMining size={70} />,
    altText: "Mining and Quarrying",
    spotlight: false,
  },
  {
    name: "Real Estate",
    icon: <MdHouse size={70} />,
    altText: "Real Estate",
    spotlight: false,
  },
  {
    name: "Religious Orgs",
    icon: <FaPrayingHands size={70} />,
    altText: "Religious Organizations",
    spotlight: false,
  },
  {
    name: "Restaurants",
    icon: <RiRestaurantLine size={70} />,
    altText: "Restaurants",
    spotlight: true,
  },
  {
    name: "Retail",
    icon: <MdOutlineShoppingBag size={70} />,
    altText: "Retail Outlets",
    spotlight: false,
  },
  {
    name: "Technology",
    icon: <GiCircuitry size={70} />,
    altText: "Technology",
    spotlight: false,
  },
  {
    name: "Transportation",
    icon: <MdOutlineAirportShuttle size={70} />,
    altText: "Transportation",
    spotlight: false,
  },
  {
    name: "Travel Agencies",
    icon: <GiAirplaneDeparture size={70} />,
    altText: "Travel Agencies",
    spotlight: false,
  },
  {
    name: "Vehicle Rental",
    icon: <FaCarSide size={70} />,
    altText: "Vehical Rental",
    spotlight: false,
  },
  {
    name: "Wholesale",
    icon: <FaBoxes size={70} />,
    altText: "Wholesale",
    spotlight: false,
  },
];

export const partnersInfo = [
  {
    title: `Higher Commission`,
    details: `We offer one of the the most generous referral programs in the small business funding industry.`,
  },
  {
    title: `Bonus Incentives`,
    details: `As if higher commission isn't enough, we offer incentives based on
    volume and units so our partners earn more.`,
  },
  {
    title: `Lightning Fast`,
    details: `With funding available in 4 to 24 hours, your clients will be back
    to growth just as fast as they applied.`,
  },
];

export const privatePolicy = [
  {
    title: `Last Updated: August 15th, 2020`,
    body: (
      <>
        Focused Financing operates{" "}
        <Link className="inline-link" to="/">
          www.focusedfinancing.com{" "}
        </Link>
        ("Site", or "Website"), which is owned and operated by Focused
        Financing, Inc. or a subsidiary thereof ("us," "we," or "our"). This
        page informs you of our policies regarding the collection, use, and
        disclosure of Personal Information we receive from users of the Site,
        and of our offered products and related services ("Services"). We use
        your Personal Information only for facilitating financing and other
        associated services through our company and Partners. By using the Site
        and completing any of our forms, applications, or documents, you agree
        to the collection and use of information in accordance with this policy.
      </>
    ),
  },
  {
    title: `Information Collection And Use`,
    body: `While using our Site, we may ask you to provide us with
    certain personally identifiable information that can be used
    to contact or identify you. Personally identifiable
    information may include, but is not limited to your name,
    business name, phone numbers, email address, physical address,
    and title ("Personal Information").`,
  },
  {
    title: `Log Data`,
    body: `Like many site operators, we collect information that your
    browser sends whenever you visit our Site ("Log Data"). This
    Log Data may include information such as your computer's
    Internet Protocol ("IP") address, browser type, browser
    version, the pages of our Site that you visit, the time and
    date of your visit, the time spent on those pages and other
    statistics. In addition, we may use third party services such
    as Google Analytics that collect, monitor and analyze this
    information.`,
  },
  {
    title: `Communications`,
    body: `We may use your Personal Information to contact you with
    newsletters, marketing or promotional materials and other
    information that may specifically pertain to you and/or your
    business.`,
  },
  {
    title: `Cookies`,
    body: `Cookies are files with small amount of data, which may include
    an anonymous unique identifier. Cookies are sent to your
    browser from a web site and stored on your computer's hard
    drive. Like many sites, we use "cookies" to collect
    information. You can instruct your browser to refuse all
    cookies or to indicate when a cookie is being sent. However,
    if you do not accept cookies, you may not be able to use some
    portions of our Site.`,
  },
  {
    title: `Security`,
    body: `The security of your Personal Information is important to us,
    but remember that no method of transmission over the Internet,
    or method of electronic storage, is 100% secure. While we
    strive to use commercially acceptable means to protect your
    Personal Information, we cannot guarantee its absolute
    security.`,
  },
  {
    title: `Changes To This Privacy Policy`,
    body: `This Privacy Policy is effective as of the aforementioned
    specified date and will remain in effect except with respect
    to any changes in its provisions in the future, which will be
    in effect immediately after being posted on this page. We
    reserve the right to update or change our Privacy Policy at
    any time and you should check this Privacy Policy
    periodically. Your continued use of the Service after we post
    any modifications to the Privacy Policy on this page will
    constitute your acknowledgment of the modifications and your
    consent to abide and be bound by the modified Privacy Policy.
    If we make any material changes to this Privacy Policy, we
    will notify you either through the email address you have
    provided us, or by placing a prominent notice on our website
    on this page.`,
  },
];

export const termsAndConditions = [
  {
    title: `Last updated: August 15th, 2020`,
    body: (
      <>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions")
        carefully before using the{" "}
        <Link className="inline-link" to="/">
          Focused Financing
        </Link>{" "}
        ("Site", "Service") operated by Focused Financing ("us", "we", or
        "our"). Your access to and use of the Service is conditioned on your
        acceptance of and compliance with these Terms. These Terms apply to all
        visitors, users, and others who access or use the Service. By accessing
        or using the Service you agree to be bound by these Terms. If you
        disagree with any part of the terms then you may not access the Service.
      </>
    ),
  },
  {
    title: `Purchases`,
    body: `If you wish to purchase any product or service made available
    through the Service ("Purchase"), you may be asked to supply
    certain information relevant to your Purchase including,
    without limitation, your Personal Information, name, business
    name, phone number, email address, physical address, and
    title.`,
  },
  {
    title: `Subscriptions`,
    body: `Some parts of the Service are billed on a subscription basis
    ("Subscription"). You may be billed in advance on a recurring
    basis as defined in the specific terms defined in your
    contract and/or agreement.`,
  },
  {
    title: `US Patriot Act`,
    body: `To help the government fight the funding of terrorism and
    money laundering activities, Federal law requires all
    financial institutions to obtain, verify, and record
    information that identifies each person and business that
    seeks a business loan. What this means for you: When you apply
    for a loan, we will ask for your business name, address, and
    Tax Identification Number. We will also ask for your name,
    address, date of birth, and other information that will allow
    us to identify you. We may also ask to see your driver’s
    license or other identifying documents.`,
  },
  {
    title: `Loans, Credit Lines, Merchant Services, and Equipment
    Services`,
    body: `The Merchant and Owner(s)/Officer(s) identified in the
    application (individually, an “Applicant”) each represents,
    acknowledges and agrees that (1) all information and documents
    provided to Focused Financing (“FF”) including credit card
    processor statements are true, accurate and complete, (2)
    Applicant will immediately notify FF of any change in such
    information or financial condition, (3) Applicant authorizes
    FF to disclose all information and documents that FF may
    obtain including credit reports to other persons or entities
    (collectively, “Assigns”) that may be involved with or acquire
    commercial loans having daily repayment features and/or
    Merchant Cash Advance transactions, including without
    limitation the application therefor (collectively,
    “Transactions”) and each Assign is authorized to use such
    information and documents, and share such information and
    documents with other Assigns, in connection with potential
    Transactions, (4) each Assign will rely upon the accuracy and
    completeness of such information and documents, (5) FF,
    Assigns, and each of their representatives, successors,
    assigns and designees (collectively, “Recipients”) are
    authorized to request and receive any investigative reports,
    credit reports, statements from creditors or financial
    institutions, verification of information, or any other
    information that a Recipient deems necessary, (6) Applicant
    waives and releases any claims against Recipients and any
    information-providers arising from any act or omission
    relating to the requesting, receiving or release of
    information, and (7) each Owner/Officer represents that he or
    she is authorized to sign this form on behalf of Merchant.(8)
    I consent to receive direct mail, faxes, text-messages, and
    e-mails sent by FF and its affiliates for the purposes of
    transmitting account updates, requests for information and
    notices, and (9) this request is for business and not for
    consumer purposes.`,
  },
  {
    title: `Text Messaging`,
    body: `By providing my wireless phone number to Focused Funding
    ("FF"), I agree and acknowledge that FF may send text and
    multimedia messages to my wireless phone number for any
    purpose. I agree that these text or multimedia messages may be
    regarding the products and/or services that I have previously
    purchased and products and/or services that FF may market to
    me. I acknowledge that this consent may be removed at my
    request but that until such consent is revoked, I may receive
    text or multimedia messages from FF to my wireless phone
    number.`,
  },
  {
    title: `Links To Other Web Sites`,
    body: `Our Service may contain links to third-party web sites or
    services that are not owned or controlled by Focused
    Financing. Focused Financing has no control over, and assumes
    no responsibility for, the content, privacy policies, or
    practices of any third party web sites or services. You
    further acknowledge and agree that Focused Financing shall not
    be responsible or liable, directly or indirectly, for any
    damage or loss caused or alleged to be caused by or in
    connection with use of or reliance on any such content, goods
    or services available on or through any such web sites or
    services.`,
  },
  {
    title: `Changes To These Terms and Conditions`,
    body: `These Terms and Conditions are effective as of the
    aforementioned specified date and will remain in effect except
    with respect to any changes in its provisions in the future,
    which will be in effect immediately after being posted on this
    page. We reserve the right to update or change our Privacy
    Policy at any time and you should check this Privacy Policy
    periodically. Your continued use of the Service after we post
    any modifications to the Privacy Policy on this page will
    constitute your acknowledgment of the modifications and your
    consent to abide and be bound by the modified Privacy Policy.
    If we make any material changes to this Privacy Policy, we
    will notify you either through the email address you have
    provided us, or by placing a prominent notice on our website
    on this page.`,
  },
];

export const resources = [
  {
    name: "Application Form",
    file: applicationForm,
    description:
      "The Focused Financing application, or pre-qualification form, is used by underwriting to collect basic business and personal information for review.",
  },
  {
    name: "Balance Sheet",
    file: BalanceSheetTemplate,
    description:
      "The balance sheet is a statement that reports a company's financial position by detailing its assets, liabilities, and shareholder equity.",
  },
  {
    name: "Debt Schedule",
    file: DebtScheduleTemplate,
    description:
      "The debt schedule lays out all of the debt a business has in a schedule based on its maturity. It is typically used to construct a cash flow analysis.",
  },
  {
    name: "Income Statement",
    file: IncomeStatement,
    description:
      "Also known as a profit & loss statement, this document summarizes the revenues, costs, and expenses incurred during a specified period, usually a quarter or fiscal year.",
  },
  {
    name: "Personal Financial Statement",
    file: PFSTemplate,
    description:
      "The personal financial statement, or PFS, is a spreadsheet that details the assets and liabilities of an individual or couple at a specific point in time. ",
  },
];

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CTAForm.css";
import "../Form.css";
import { Logo } from "../Logo";
import { Button } from "../Button/Button";
import { includeCommas, includePhoneSymbols, capitalize } from "../../utils";

export const CTAForm = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [annualRev, setAnnualRev] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/apply", {
      state: {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        annualRev: annualRev,
      },
    });
  };
  return (
    <div className="hero-form-section">
      <div className="hero-form-container">
        <div className="intro-form-title">
          <div className="form-img-wrapper">
            <Logo width="75px" height="75px" />
          </div>
          <h2 className="form-title">Get Started Now!</h2>
        </div>

        <form
          name="indexHeader"
          id="indexForm"
          className="hero-form"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              name="firstname"
              value={firstName}
              onChange={(e) => setFirstName(capitalize(e.target.value))}
              maxLength="50"
              pattern="[a-zA-Z\-\,.]+"
              title="Ex. George, Mary-Kate"
              required
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              name="theLastname"
              value={lastName}
              onChange={(e) => setLastName(capitalize(e.target.value))}
              maxLength="50"
              pattern="[a-zA-Z\-\,.]+"
              title="Ex. Cohen; Zeta-Jones; Williams, Jr."
              required
            />
          </div>

          <div className="form-group">
            <input
              type="tel"
              className="form-control phone"
              placeholder="Phone"
              name="thePhone"
              value={phone}
              onChange={(e) => setPhone(includePhoneSymbols(e.target.value))}
              maxLength="14"
              title="Ex. (212) 555-1212"
              required
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              name="theEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength="60"
              pattern="^\S+@\S+\.\S+$"
              title="Ex. Example@Website.com"
              required
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control money"
              placeholder="Annual Revenue"
              name="monthlyRev"
              value={annualRev}
              onChange={(e) => setAnnualRev(includeCommas(e.target.value))}
              maxLength="12"
              pattern="^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$"
              title="Ex. $8,500"
              required
            />
          </div>

          <div className="form-submit">
            <Button name="submit" type="submit" buttonStyle="btn--dark">
              Apply
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

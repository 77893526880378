import { useEffect } from "react";

const ScrollLocationOnMount = ({ url, target }) => {
  useEffect(() => {
    if (target === "" || typeof target === "undefined") {
      window.scrollTo({ top: "0", left: "0", behavior: "smooth" });
    } else {
      setTimeout(() => {
        const id = target.replace("#", "");
        let element = document.getElementById(id);
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 0);
    }
  }, [url, target]);
  return null;
};

export default ScrollLocationOnMount;

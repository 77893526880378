import React from "react";
import "./Partners.css";
import { partnersInfo } from "../../pages/data";
import { Button } from "../Button/Button";

const Partners = () => {
  return (
    <section className="partners-info-section">
      <header className="partners-info-header">
        <div className="partners-info-title">
          <h3>
            <em>Best-in-class</em> programs
          </h3>
          <h3>for </h3>
          <h3>
            <em>Best-in-class</em> partners
          </h3>
        </div>
        <p className="partners-info-description">
          Enjoy competitive payouts by offering you clients better financing
          options.
        </p>
      </header>
      <div className="partners-info-body">
        {partnersInfo.map((partnerItem, idx) => (
          <div
            key={partnerItem + idx.toString()}
            className="partner-info-container"
          >
            <h4 className="partner-info-title">{partnerItem.title}</h4>
            <p className="partner-info-description">{partnerItem.details}</p>
          </div>
        ))}
      </div>
      <a
        className="partner-cta"
        href="mailto:partners@focusedfinancing.com?subject=ISO Partner Request"
        target="_blank"
        rel="noreferrer"
      >
        <Button buttonStyle="btn--dark">Become a Partner</Button>
      </a>
    </section>
  );
};

export default Partners;

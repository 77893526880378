export const includeCommas = (number) => {
  var valueSansComma = number.replace(/,/gi, "");
  var valueSansDollar = valueSansComma.replace("$", "");
  var valueWithComma = valueSansDollar.split(/(?=(?:\d{3})+$)/).join(",");

  return "$" + valueWithComma;
};

export const includePhoneSymbols = (number) => {
  number = number.replace(/[^0-9]/g, "");
  if (number.length < 4) return number;
  if (number.length < 7) return `(${number.slice(0, 3)}) ${number.slice(3)}`;
  return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
};

export const capitalize = (input) => {
  if (!input.includes(" "))
    return input.charAt(0).toUpperCase() + input.slice(1);

  let words = input.split(" ");
  let capitalizedWords = [];

  for (var word of words) {
    var firstLetter = word.charAt(0).toUpperCase();
    capitalizedWords.push(firstLetter + word.slice(1));
  }
  return capitalizedWords.join(" ");
};

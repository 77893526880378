import React from "react";
import { Link } from "react-router-dom";
import "./ProgramCard.css";

const ProgramCard = ({ cardTitle, cardName, cardImage, imageAlt }) => {
  return (
    <Link to={`/programs#${cardName}`} className="program-card">
      <div className="card-image-container">
        <img
          className="card-image"
          src={cardImage}
          alt={imageAlt}
          width="100%"
        />
      </div>
      <div className="card-title-container">
        <div className="card-title-content">
          <h3>{cardTitle}</h3>
          <h4 className="scrolled-learn-more">Learn More</h4>
        </div>
      </div>
    </Link>
  );
};

export default ProgramCard;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage/HomePage";
import SupplementaryPage from "./pages/SupplementaryPage/SupplamentaryPage";
import Footer from "./components/Footer/Footer";
import ApplicationPage from "./pages/ApplicationPage/ApplicationPage";
import Submitted from "./pages/Submitted/Submitted";
import Page404 from "./pages/404/Page404";
import ReactGA from "react-ga";
const TRACKING_ID = "G-E6E5RDWH8V";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [isMobile, setIsMobile] = useState();
  const detectSize = () => {
    if (window.innerWidth <= 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    detectSize();
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  window.addEventListener("resize", detectSize);
  return (
    <Router>
      <div className="app-wrapper">
        <Navbar />
        <Routes>
          <Route exact path="/" element={<HomePage isMobile={isMobile} />} />
          <Route
            path="/programs"
            element={
              <SupplementaryPage
                pageType="programs--page"
                isMobile={isMobile}
              />
            }
          />
          <Route
            path="/industries"
            element={
              <SupplementaryPage
                pageType="industry--page"
                isMobile={isMobile}
              />
            }
          />
          <Route
            path="/partners"
            element={
              <SupplementaryPage
                pageType="partners--page"
                isMobile={isMobile}
              />
            }
          />
          <Route
            path="/privacy"
            element={
              <SupplementaryPage pageType="privacy--page" isMobile={isMobile} />
            }
          />
          <Route
            path="/terms"
            element={
              <SupplementaryPage pageType="terms--page" isMobile={isMobile} />
            }
          />
          <Route
            path="/resources"
            element={
              <SupplementaryPage
                pageType="resources--page"
                isMobile={isMobile}
              />
            }
          />
          <Route path="/apply" element={<ApplicationPage />} />
          <Route path="/submitted" element={<Submitted />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-info">
          <div className="quick-links learn-more">
            <h4>About Us</h4>
            <ul>
              <li>
                <Link className="footer-link" to="/programs">
                  Programs
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="../partners">
                  Partners
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="../resources">
                  Resources
                </Link>
              </li>
            </ul>
          </div>
          <div className="quick-links legal">
            <h4>Legal</h4>
            <ul>
              <li>
                <Link className="footer-link" to="../privacy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="../terms">
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
          <div className="quick-links contact">
            <h4>Contact Us</h4>
            <ul>
              <li>
                <Link className="footer-link" to="../apply">
                  <strong>Apply Now</strong>
                </Link>
              </li>
              <li>
                <a className="footer-link" href="tel:+19173100919">
                  (917) 310-0919
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="mailto:info@focusedfinancing.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@focusedfinancing.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-copywrite">
          Products offered by Focused Financing and affiliates are business
          loans only. In California, loans made or arranged pursuant to the
          California Financing Law, Division 9 (commencing with Section 22000)
          of the Finance Code.
        </div>
      </div>
    </footer>
  );
};

export default Footer;

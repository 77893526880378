import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./FooterCTA.css";
import { Button } from "../Button/Button";
import { includeCommas } from "../../utils";

const FooterCTA = () => {
  const [amtReq, setAmtReq] = useState("");
  let navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/apply", { state: { fundingAmt: amtReq } });
  };
  return (
    <div className="footer-cta-container">
      <header className="footer-cta-header">
        <h2>Focused on You</h2>
      </header>
      <div className="footer-cta">
        <form
          name="featured"
          id="featuredForm"
          method="GET"
          onSubmit={handleSubmit}
        >
          <h3>Secure your business loan today</h3>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="How much do you need?"
              value={amtReq}
              onChange={(e) => setAmtReq(includeCommas(e.target.value))}
              title="Ex. $4,589"
              name="amountReq"
              pattern="^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$"
              maxLength="12"
            />
          </div>

          <div className="form-group">
            <Button
              name="submit"
              type="submit"
              buttonStyle="btn--primary"
              buttonSize="btn--small"
            >
              Get Approved
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FooterCTA;

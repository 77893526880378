import React from "react";
import "./Button.css";
import { BiDownload } from "react-icons/bi";
import { IconContext } from "react-icons";

const STYLES = ["btn--primary", "btn--outline", "btn--dark"];

const SIZES = [
  "btn--small",
  "btn--medium",
  "btn--large",
  "btn--mobile",
  "btn--wide",
];

const FUNCTIONS = ["arrow", "download"];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor,
  buttonFunction,
  className,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[1];
  const checkButtonColor = STYLES.includes(buttonColor) ? buttonColor : "";
  const checkButtonFunction = FUNCTIONS.includes(buttonFunction)
    ? buttonFunction
    : "";

  const classProp = className ? className : "";

  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor} ${classProp} ${checkButtonFunction}`}
      onClick={onClick}
      type={type}
    >
      {children}
      {checkButtonFunction === "arrow" && (
        <svg
          className="hover-arrow"
          width="20"
          height="20"
          viewBox="0 0 10 10"
          aria-hidden="true"
        >
          <g fillRule="evenodd">
            <path className="hover-arrow__line-path" d="M0 5 h7"></path>
            <path className="hover-arrow__tip-path" d="M1 1l4 4-4 4"></path>
          </g>
        </svg>
      )}
      {checkButtonFunction === "download" && (
        <IconContext.Provider value={{ color: "var(--focused-white)" }}>
          <BiDownload />
        </IconContext.Provider>
      )}
    </button>
  );
};

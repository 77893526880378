import React from "react";
import { Button } from "../Button/Button";
import "./HeroSection.css";
import { CTAForm } from "../CTAForm/CTAForm";

const PAGES = ["default", "hero--homepage"];

const HeroSection = ({ page }) => {
  const checkPageType = PAGES.includes(page) ? page : PAGES[0];

  return (
    <section className={`hero ${checkPageType}`}>
      <div className="hero-container">
        <header className="hero-header hero-section">
          <div className="hero-lead-in">
            <h1>
              <em>Focused </em>
              is the fastest and easiest way to get business funding
            </h1>
          </div>

          <div className="hero-action-button-container">
            <a href="tel:+19173100919">
              <Button buttonStyle="btn--dark">Call Now</Button>
            </a>
          </div>
        </header>
        <section className="hero-body">
          <CTAForm />
        </section>
      </div>
    </section>
  );
};

export default HeroSection;

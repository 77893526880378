import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { Button } from "../Button/Button";
import "./Navbar.css";
import { Logo } from "../Logo";
import { IconContext } from "react-icons/lib";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener("resize", showButton);
  return (
    <>
      <IconContext.Provider value={{ color: "var(--focused-dark)" }}>
        <div className="navbar">
          <div className="navbar-container container">
            <NavLink to="/" className="navbar-logo">
              <Logo width="50px" height="50px" />
              <div className="navbar-title">
                <h1>Focused </h1>
                <h1>Financing</h1>
              </div>
            </NavLink>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item" onClick={closeMobileMenu}>
                <NavLink to="/" className="nav-links">
                  Home
                </NavLink>
              </li>
              <li className="nav-item" onClick={closeMobileMenu}>
                <NavLink to="/programs" className="nav-links">
                  Programs
                </NavLink>
              </li>
              <li className="nav-item" onClick={closeMobileMenu}>
                <NavLink to="/industries" className="nav-links">
                  Industries
                </NavLink>
              </li>
              <li className="nav-item" onClick={closeMobileMenu}>
                <NavLink to="/partners" className="nav-links">
                  Partners
                </NavLink>
              </li>
              <li className="nav-btn" onClick={closeMobileMenu}>
                {button ? (
                  <NavLink to="/apply" className="btn-link">
                    <Button buttonStyle="btn--outline">Apply Now</Button>
                  </NavLink>
                ) : (
                  <NavLink to="/apply" className="btn-link">
                    <Button buttonStyle="btn--outline" buttonSize="btn--mobile">
                      Apply Now
                    </Button>
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;

import React from "react";
import "./Legal.css";

const Legal = ({ legalInfo }) => {
  return (
    <div className="legal-info-container">
      {legalInfo.map((legalItem, idx) => (
        <div key={legalItem + idx.toString()} className="legal-info">
          <div className="legal-title">
            <h3>{legalItem.title}</h3>
          </div>
          <div className="legal-detail">{legalItem.body}</div>
        </div>
      ))}
    </div>
  );
};

export default Legal;

import React from "react";
import "./ContactSection.css";

const ContactSection = ({ isMobile }) => {
  return (
    <div className={`contact-body ${isMobile ? "body-column" : ""}`}>
      <div className="contact-info contact-map-container">
        <iframe
          title="google maps"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11907.126665641832!2d-74.0794612!3d41.746802!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc236ea85b85a76eb!2sFocused+Financing!5e0!3m2!1sen!2sus!4v1562263443629!5m2!1sen!2sus"
          width="100%"
          height="450"
          frameBorder="0"
          className="contact-map"
          allowFullScreen
        ></iframe>
      </div>
      <div className="contact-info contact-details">
        <div className="contact-item">
          <h4>Location</h4>
          <p>171 Main Street</p>
          <p>New Paltz, NY 12561</p>
        </div>
        <div className="contact-item">
          <h4>Hours</h4>
          <p>Mon - Fri: 9:30 - 5:30 EST</p>
          <p>Sat - Sun: Closed</p>
        </div>
        <div className="contact-item">
          <h4>Email</h4>
          <p>info@focusedfinancing.com</p>
        </div>
        <div className="contact-item">
          <h4>Phone</h4>
          <p>(917) 310-0919</p>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;

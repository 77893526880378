import React from "react";
import "./Certifications.css";
import Google from "./Google.png";
import TrustPilot from "./TrustPilot.png";
import BestCompany from "./BestCompany";

const Certifications = () => {
  return (
    <aside className="certs">
      <div className="certs-container">
        <div className="cert-item">
          <a
            href="https://www.google.com/search?q=focused+financing&sxsrf=APq-WBsbjK4oW3jwk4Z0wTxWHgO3tO6zLg%3A1648330148561&source=hp&ei=pIU_YvTnHZOhptQPgYWjgAc&iflsig=AHkkrS4AAAAAYj-TtG38IfT1ldVA6V2ngZiWuaSCoaLs&ved=0ahUKEwj0yeSn3OT2AhWTkIkEHYHCCHAQ4dUDCAk&uact=5&oq=focused+financing&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyCwguEIAEEMcBEK8BMgYIABAWEB4yBQgAEIYDMgUIABCGAzIFCAAQhgMyBQgAEIYDOgcIIxDqAhAnOhEILhCABBCxAxCDARDHARDRAzoICAAQsQMQgwE6CAguELEDEIMBOgUIABCABDoICAAQgAQQsQM6CAguEIAEELEDOg0ILhCABBCHAhCxAxAUOhEILhCABBCxAxDHARDRAxDUAjoNCAAQgAQQhwIQsQMQFDoFCAAQsQM6BQguEIAEOgcIABCABBAKOg0ILhCABBDHARCvARAKOgcIIxCxAhAnOgQIABAKOgoILhDHARCvARAKOgcIIxCwAhAnOgQIABANOg0ILhDHARCvARDUAhANOgoILhDHARCvARANOgQILhANOg4ILhCABBDHARCvARDUAlCvBVjNJmDULmgEcAB4AIABogGIAegRkgEENC4xNpgBAKABAbABCg&sclient=gws-wiz#lrd=0x89dd23738ad0a125:0xc236ea85b85a76eb,1,,,"
            target="_blank"
            rel="noreferrer"
            className="cert-link"
          >
            <img
              src={Google}
              className="cert-img"
              width="120px"
              height="50px"
              alt="Google | 5 Stars"
            />
          </a>
        </div>

        <div className="cert-item">
          <a
            href="https://bestcompany.com/business-loans/company/focused-financing"
            target="_blank"
            rel="noreferrer"
            className="cert-link"
          >
            <BestCompany className="cert-img" />
          </a>
        </div>
        <div className="cert-item">
          <a
            href="https://www.trustpilot.com/review/focusedfinancing.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={TrustPilot}
              className="cert-img"
              width="120px"
              height="50px"
              alt="TrustPilot | 5 Stars"
            />
          </a>
        </div>
      </div>
    </aside>
  );
};

export default Certifications;

import React from "react";
import "./IndustryCardGrid.css";
import IndustryCard from "../IndustryCard/IndustryCard";
import { industryArray } from "../../pages/data";
import { VscEllipsis } from "react-icons/vsc";

const IndustryCardGrid = (props) => {
  let cardList;
  if (props.hasOwnProperty("spotlight")) {
    cardList = industryArray.filter((item) => item.spotlight === true);
    if (props.hasOwnProperty("maxLength")) {
      cardList = cardList.slice(0, props.maxLength);
    }
  } else if (props.hasOwnProperty("maxLength")) {
    cardList = industryArray.slice(0, props.maxLength);
  } else {
    cardList = industryArray;
  }

  const sortedList = cardList.sort((curr, next) => {
    return curr.name.toUpperCase() < next.name.toUpperCase() ? -1 : 0;
  });

  return (
    <section className="industry-card-container">
      {sortedList.map((industry, idx) => {
        return (
          <IndustryCard
            key={industry.name + idx.toString}
            industryName={industry.name}
            industryIcon={industry.icon}
            industryAltText={industry.altText}
          />
        );
      })}
      <IndustryCard
        industryName="...and More!"
        industryIcon={<VscEllipsis size={70} />}
        industryAltText="ellipsis"
      />
    </section>
  );
};

export default IndustryCardGrid;

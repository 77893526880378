import React from "react";
import "./PageSection.css";

const SECTION_TYPES = [
  "default",
  "programs--section",
  "industries--section",
  "contact--section",
];

const SECTION_FLOW = {
  default: {
    sectionContentFlow: "section--flow--column",
    headerContentFlow: "header--flow--column",
    bodyContentFlow: "body--flow--column",
  },
  "programs--section": {
    sectionContentFlow: "section--flow--column",
    headerContentFlow: "header--flow--row",
    bodyContentFlow: "body--flow--row",
  },
  "industries--section": {
    sectionContentFlow: "section--flow--row",
    headerContentFlow: "header--flow--column",
    bodyContentFlow: "body--flow--row",
  },
  "contact--section": {
    sectionContentFlow: "section--flow--column",
    headerContentFlow: "header--flow--column",
    bodyContentFlow: "body--flow--row",
  },
};

const PageSection = ({
  sectionType,
  isMobile,
  headerTitle,
  headerDesc,
  bodyContent,
  CTA,
}) => {
  const passedSectionType = SECTION_TYPES.includes(sectionType)
    ? sectionType
    : SECTION_TYPES[0];

  const impliedFlow = isMobile
    ? SECTION_FLOW["default"]
    : SECTION_FLOW[passedSectionType];

  return (
    <section className={`page-section ${passedSectionType}`}>
      <div className={`section-container ${impliedFlow.sectionContentFlow}`}>
        <header className={`section-content ${impliedFlow.headerContentFlow}`}>
          {headerTitle}
          {headerDesc && headerDesc}
        </header>
        <section className={`section-content ${impliedFlow.bodyContentFlow}`}>
          {bodyContent}
        </section>
        {CTA && CTA}
      </div>
    </section>
  );
};

export default PageSection;

import React from "react";

export const Logo = ({ height, width }) => {
  return (
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
    >
      <path
        d="M50 0
      V18
      L18 50 
      L26 59 
      L50 36 
      V51
      L34 67 
      L50 82 
      V100 
      L0 50 
      L50 0"
        fill="#0074ff"
      />
      <path
        d="M50 0 
       V18 
       L82 50 
       L74 59 
       L50 36 
       V51 
       L66 67 
       L50 82 
       V100 
       L100 50
       L50 0"
        fill="#9fc1f8"
      />
      {/*
      <path d="M50 51 L34 67 L50 82 L66 67 L50 51" fill="#2d6091" />
      <path
        d="M18 50 
       L50 18 
       L82 50 
       L74 59 
       L50 36 
       L26 59 
       L18 50"
        fill="#2d6091"
  />*/}
    </svg>
  );
};

import React from "react";
import "./IndustryCard.css";
const IndustryCard = ({ industryName, industryIcon, imageAltText }) => {
  return (
    <div className="industry-card">
      <div className="industry-icon-container">{industryIcon}</div>
      <div className="industry-name-container">
        <h4 className="industry-name">{industryName}</h4>
      </div>
    </div>
  );
};

export default IndustryCard;

import React from "react";
import "./Resources.css";
import { resources } from "../../pages/data";
import { Button } from "../Button/Button";

const Resources = () => {
  return (
    <section className="resources-section">
      <header className="resources-header">
        <div className="resources-title">
          <h3>
            Tools <em>&</em> Templates
          </h3>
        </div>
        <p className="resources-description">
          Please note that these links will initiate downloads.
        </p>
      </header>
      <div className="resources-body">
        {resources.map((resource, idx) => (
          <div
            key={resource.name + idx.toString()}
            className="resource-item-container"
          >
            <h4 className="resource-item-title">{resource.name}</h4>
            <div className="resource-info">
              <p className="resource-item-description">
                {resource.description}
              </p>
              <a
                className="resource-item-download"
                href={resource.file}
                download
              >
                <Button buttonStyle="btn--dark" buttonFunction="download">
                  Download
                </Button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Resources;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import "./ApplicationPage.css";
import ScrollLocationOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";
import { industryArray } from "../data";
import { includeCommas, includePhoneSymbols, capitalize } from "../../utils";

const fundingPurposes = [
  "Equipment",
  "Expansion",
  "Inventory",
  "Marketing",
  "Payroll",
  "Remodel Location",
  "Refinancing Debt",
  "Working Capital",
];

const ApplicationPage = (props) => {
  var { state } = useLocation();
  state = state ? state : {};
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(
    state.firstName === null ? "" : state.firstName
  );
  const [lastName, setLastName] = useState(
    state.lastName === null ? "" : state.lastName
  );
  const [phone, setPhone] = useState(state.phone === null ? "" : state.phone);
  const [email, setEmail] = useState(state.email === null ? "" : state.email);
  const [businessName, setBusinessName] = useState("");
  const [industry, setIndustry] = useState("");
  const [annualRev, setAnnualRev] = useState(
    state.annualRev === null ? "" : state.annualRev
  );
  const [fundingAmt, setFundingAmt] = useState(
    state.fundingAmt === null ? "" : state.fundingAmt
  );
  const [purpose, setPurpose] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setisSubmitting(true);
    await fetch("https://focusedfinancing.com/api/send", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        businessName: businessName,
        industry: industry,
        annualRev: annualRev,
        fundingAmt: fundingAmt,
        purpose: purpose,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        navigate("/submitted");
      });
  };

  return (
    <>
      <ScrollLocationOnMount />
      <section
        className="application-form-section fixed-p2v-gradient"
        id="contact"
      >
        <div className="application-form-container">
          <header className="application-header-section">
            <h2 className="application-heading">Apply Now</h2>
            <p className="application-subtitle">
              Questions?
              <br /> Give us a call at{" "}
              <a href="tel:+19173100919">(917) 310-0919</a>
            </p>
          </header>

          <div className="application-form-body">
            <form
              className="application-form"
              name="sentMessage"
              id="contactForm"
              method="POST"
              action="/"
              onSubmit={handleSubmit}
            >
              <div className="form-section">
                <h3 className="form-section-header">Contact Information</h3>

                <div className="form-group">
                  <label htmlFor="firstname">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="firstname"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(capitalize(e.target.value));
                    }}
                    maxLength="50"
                    pattern="[a-zA-Z\-\,\.]+"
                    title="Ex. George, Mary-Kate"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="lastname"
                    value={lastName}
                    onChange={(e) => setLastName(capitalize(e.target.value))}
                    maxLength="50"
                    pattern="[a-zA-Z\-\,\.]+"
                    title="Ex. Cohen; Zeta-Jones; Williams, Jr."
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    className="form-control phone"
                    placeholder="Phone"
                    name="phone"
                    value={phone}
                    onChange={(e) =>
                      setPhone(includePhoneSymbols(e.target.value))
                    }
                    maxLength="14"
                    pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                    title="Ex. 212-555-1212"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    maxLength="60"
                    pattern="^\S+@\S+\.\S+$"
                    title="Ex. Example@Website.com"
                    required
                  />
                </div>
              </div>
              <div className="form-section">
                <h3 className="form-section-header">Business Information</h3>

                <div className="form-group">
                  <label htmlFor="businessname">Business Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Business Name"
                    name="businessname"
                    value={businessName}
                    onChange={(e) =>
                      setBusinessName(capitalize(e.target.value))
                    }
                    maxLength="60"
                    pattern="([a-zA-Z0-9]).+"
                    title="Ex. Joe's Pizza"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="industry">Industry</label>
                  <select
                    className="form-control"
                    name="industry"
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      --Please Select--
                    </option>
                    {industryArray.map((industry, idx) => {
                      return (
                        <option
                          key={`${industry.name}-${idx}`}
                          value={industry.name}
                        >
                          {industry.name}
                        </option>
                      );
                    })}
                    <option value="other">Other</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="revenue">Annual Revenue</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Annual Revenue"
                    name="revenue"
                    value={annualRev}
                    onChange={(e) => {
                      setAnnualRev(includeCommas(e.target.value));
                    }}
                    maxLength="12"
                    pattern="^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$"
                    title="Ex. $1,000,000"
                    required
                  />
                </div>
              </div>
              <div className="form-section">
                <h3 className="form-section-header">Funding Information</h3>

                <div className="form-group">
                  <label htmlFor="amount">Amount Requested</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Amount Requested"
                    name="amount"
                    value={fundingAmt}
                    onChange={(e) =>
                      setFundingAmt(includeCommas(e.target.value))
                    }
                    maxLength="12"
                    pattern="^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$"
                    title="Ex. $1,000,000"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="notes">Purpose of Funding</label>
                  <select
                    className="form-control"
                    name="notes"
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      --Please Select--
                    </option>
                    {fundingPurposes.map((purpose, idx) => {
                      return (
                        <option key={`${purpose}-${idx}`} value={purpose}>
                          {purpose}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-section submit-section">
                <Button
                  className="submit-button"
                  name="submit"
                  type="submit"
                  buttonStyle="btn--dark"
                  disabled
                >
                  {isSubmitting ? "Submitting" : "Get Approved"}
                </Button>
                <p>
                  <em>
                    By continuing, you agree to our{" "}
                    <a href="../terms">terms of service</a>.
                  </em>
                </p>
              </div>
            </form>
          </div>

          <div className="col-lg-12 text-center">
            <script language="JavaScript" type="text/javascript">
              TrustLogo( "https://www.focusedfinancing.com/img/comodo.png",
              "CL1", "none" );
            </script>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApplicationPage;

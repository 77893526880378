import React from "react";
import "./Programs.css";
import { programInfo } from "../../pages/data";

const Programs = () => {
  return programInfo.map((program, idx) => (
    <section
      key={program.title + idx.toString()}
      className="program-info-section"
      id={program.name}
    >
      <header className="program-info-header">
        <h3>{program.title}</h3>
      </header>
      <div className="program-info-body">
        <picture className="program-image">
          <source media="(min-width:1150px)" srcSet={program.image} />
          <img
            className="program-image"
            src=""
            alt={program.alt}
            width="500px"
            height="334px"
          />
        </picture>

        <div className="program-info">
          <div className="program-detail-container">
            <p className="program-description">{program.desc}</p>
          </div>
          <div className="program-detail-container">
            <ul className="program-terms-list">
              {program.notes.map((note, idx) => {
                return (
                  <li
                    key={program.title + note[0] + idx.toString()}
                    className="program-term-row"
                  >
                    <h4 className="program-term-label">{note[0]}</h4>
                    <p className="program-term-detail">{note[1]}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  ));
};

export default Programs;

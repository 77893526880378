import React, { useState, useEffect } from "react";
import "./Page404.css";
import { Link, useNavigate } from "react-router-dom";

const Page404 = () => {
  const [time, setTime] = useState(5);
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((t) => t - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (time === -1) {
      navigate("/");
    }
  }, [time, navigate]);

  return (
    <section className="page-not-found fixed-p2v-gradient">
      <h2>Oops, you've seemed to have gotten lost!</h2>
      <p>
        You will be redirected to the home page in {time} seconds, or click{" "}
        {<Link to="/">here</Link>} to navigate immediately.
      </p>
    </section>
  );
};

export default Page404;
